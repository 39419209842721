import React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {colors} from '../../media/index';



export const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    // border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    'hover':{
      backgroundColor: colors.hightOrange,
      backgroundOpacity: 0.4
    }
}));
  
export const AccordionSummary = styled((props) => (
<MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
/>
))(({ theme }) => ({
backgroundColor: colors.lowBrown,
'.MuiTypography-root': {
    color: colors.yellow,
},
flexDirection: 'row-reverse',
'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'

},
'& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
 },
// '& .MuiAccordionSummary-expandIconWrapper':{
//   color: colors.yellow
// }
}));

