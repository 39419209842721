import React, { useEffect, useState } from 'react'
import './devis.scss'
import { AccordionDetails, FormControl, InputAdornment, InputLabel, OutlinedInput, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary } from './sharedComponents/Accordion';
import MultipleSelectCheckmarks from './sharedComponents/Select.jsx'
import LinearProgressWithLabel from './sharedComponents/LinearProgressWithLabel';
import {mediaBank} from '../media/index.js';
import { ThemeProvider } from '@mui/material/styles';
import theme  from '../components/sharedComponents/Theme.js';
import InputNumber from './sharedComponents/InputNumber';
import { colors } from '../media/index.js';
import { actionTypes } from '../reducers/devisReducer';
import { useStateValue } from '../reducers/devisContext';
import DevisMail from './DevisMail';

function Devis() {
  const prestaGraphiques = mediaBank.prestations.graphisme.prestagrapiques;
  const prestaWeb = mediaBank.prestations.web.prestaweb;
  const arrDevis= mediaBank.prestations.web.site.tarif.options.devis;
  const { state, dispatch } = useStateValue();
  const [graphExpanded, setGraphExpanded] = useState(false);
  const [webExpanded, setwebExpanded] = useState(false);

  useEffect(() => {
    toggleService('graphique', graphExpanded);
    toggleService('web', webExpanded);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  

  function getInputs(prest, services, setServices, name ) {
    const finalName = name || prest.name;
    const finalKey = prest.idService || prest.idType || prest.id;
    const display = getDisplay(prest);
    let input;
      switch(prest.type) {
        case 'select':
          input = <MultipleSelectCheckmarks id={finalKey} display={display} prest={prest} className={(prest.lexique && state.helper) ? 'aide' : '' } name={finalName} key={finalKey} setServices={setServices} services={services}/>;
          break;
        case 'number':
          input = <InputNumber id={finalKey} prest={prest} display={display} className={(prest.lexique && state.helper) ? 'aide' : '' } name={finalName} key={finalKey} setServices={setServices} services={services}/>;
          break;
        case 'section':
          input =  (
          <div key={`section-${prest.idService}-${prest.title}`}>
            {prest.title && <h5>{prest.title}</h5>}
            {prest.types.map(type => getInputs(type, services, setServices, prest.title))}
          </div>
          )
          break;
        default:
          input = null;
          break;
      }
    return input;
  }

  function getDisplay(prest) {
    if (prest.dependOf) {
      if(document.getElementById(prest.dependOf) && document.getElementById(prest.dependOf).value && document.getElementById(prest.dependOf).value > 0 ) {
        return 'block';
      } else {
        return 'none';
      }
    } else { 
      return 'block';
    }
  }
  
  function toggleService(service, expanded) {
    service === 'graphique' ? setGraphExpanded(expanded) : setwebExpanded(expanded);
    switch (service) {
      case 'graphique':
        if (expanded) {
          dispatch({type: actionTypes.SET_SERVICE_GRAPHIQUE, payload: state.serviceGraphique});
        } else {
          dispatch({type: actionTypes.SET_CONSOMME_GRAPHIQUE, payload: 0});
        }
        break;
      case 'web':
        if (expanded) {
          dispatch({type: actionTypes.SET_SERVICE_WEB, payload: state.serviceWeb});
          dispatch({type: actionTypes.SET_DEVIS, payload: state.devis});
        } else {
          dispatch({type: actionTypes.SET_CONSOMME_WEB, payload: 0});
        }
        break;
      default:
        break;
    }
          
  }
  
  return (
    <ThemeProvider theme={theme}>
      <div className='devis'>
        <h2>Devis personnalisable</h2>
        <FormControl  sx={{ m: 1 }} value={state.budgetGlobal} onChange={(e) => dispatch({type: actionTypes.SET_BUDGET_GLOBAL, payload: e.target.value})}>
          <InputLabel color='hightOrange' sx={{color: colors.hightOrange+ '! important', backgroundColor: colors.yellow}} htmlFor="outlined-adornment-amount">Budget Global</InputLabel>
          <OutlinedInput
            autoFocus='true'
            color='hightOrange'
            sx={{ border: `3px solid ${colors.lowBrown}`, '&:hover': {border: `3px solid ${colors.hightOrange}`, ".css-hfutr2-MuiSvgIcon-root-MuiSelect-icon": {color: colors.hightOrange}}, '&.Mui-focused': { border: `2px solid ${colors.hightOrange}`, ".MuiSelect-iconOpen": {color: colors.hightOrange}}, '.MuiOutlinedInput-notchedOutline': {border: 'none !important'}, '.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon': {color: colors.lowBrown}}}
            type="number"
            id="outlined-adornment-amount"
            inputProps={{
              step: 10,
              min: 0
            }}
            endAdornment={<InputAdornment position="end">€</InputAdornment>}
            label="Amount"
          />
        </FormControl>
        {state.budgetGlobal > 0 && state.budgetGlobal < (state.consommeGraphique + state.consommeWeb) && <Typography color='hightOrange' component='h4'>Votre budget est dépassé!!!!!!</Typography>}
        {state.budgetGlobal > 0 && <LinearProgressWithLabel id="budgetGlobal" budgetglobal={Number(state.budgetGlobal)} consomme={state.consommeWeb+state.consommeGraphique} value={(state.consommeWeb+state.consommeGraphique)/state.budgetGlobal*100}/>}
        <Accordion onChange={(e,expanded) => toggleService('graphique', expanded)}>
          <AccordionSummary
          expandIcon={<ExpandMoreIcon fontSize='large'color='yellow'/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
          >
          <Typography component='h3'>Service graphique (budget consommé : <strong>{state.consommeGraphique} €</strong>)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Veuillez choisir les services graphiques proposés avec les options correspondantes
            </Typography>
            {prestaGraphiques.map(prest => getInputs(prest, 'serviceGraphique', actionTypes.SET_SERVICE_GRAPHIQUE))}
          </AccordionDetails>
        </Accordion>
        <Accordion onChange={(e,expanded) => toggleService('web', expanded)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon fontSize='large'color='yellow'/>}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography component='h3'>Site web (budget consommé : <strong>{state.consommeWeb} €</strong>)</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography >
              Veuillez choisir les services web proposés avec les options correspondantes.
            </Typography>
            {prestaWeb.map(prest => getInputs(prest, 'serviceWeb', actionTypes.SET_SERVICE_WEB))}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon fontSize='large'color='yellow'/>}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography component="h3">Devis</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography >
                  Veuillez choisir les services web proposés avec les options correspondantes.
                </Typography>
                {arrDevis.map(devis => getInputs(devis, 'devis', actionTypes.SET_DEVIS))}
              </AccordionDetails>
            </Accordion>
          </AccordionDetails>
        </Accordion>
      </div>
      <DevisMail />
    </ThemeProvider>
  )

}

export default Devis