import React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function LinearProgressWithLabel(props) {
  
    return (
      <Box sx={{ margin:'20px',display: 'flex',flexFlow:'column wrap', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress  sx={{margin: '0 auto' ,width: '50%', height:'20px', borderRadius:'50px', border:'5px solid #ff591c'}} color="hightOrange" variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography sx={{ fontSize:'20px', fontWeight:'bold' }} variant="body2" color="text.secondary"><span className='spanValidation'>{props.consomme}€</span>/{props.budgetglobal}€</Typography>
        </Box>
      </Box>
    );
  }
  
  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number,
    consomme: PropTypes.number,
    budgetGlobal: PropTypes.number,
  };