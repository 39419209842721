import React from 'react';
import { lexique } from '../media/index';
import { useStateValue } from '../reducers/devisContext';
import '../styles/styles.css';

function Comment() {
    // eslint-disable-next-line no-unused-vars
    const { state, dispatch } = useStateValue();
  return (
    <section id="idComment" className="contentBlock">
        <h1>Comment nous travaillons?</h1>
        <section className="secSidebars">
            <aside>
                <h2>Création de site web</h2>
                    <div className='divoList'>
                            <h3 className='h3List'><strong>1.</strong> Analyse du besoin du projet:</h3>
                            <ol>
                                <li>Etude de faisabilité</li>
                                <li>Etablir un périmetre <span className='spanValidation'>{'=>'} validation</span></li>
                                <li>Etablir un cahier des charges <span className='spanValidation'>{'=>'} validation</span></li>
                                <li><span className={state.helper ? 'aide' : '' } title={state.helper ? lexique.siteWeb.arborescence : ''}>Arborescence </span><span className='spanValidation'>{'=>'} validation</span></li>
                                <li>Analyse concurentiel (optionnel)</li>
                                <li>Etude  <span className={state.helper ? 'aide' : '' } title={state.helper ? lexique.siteWeb.stack : ''}>stack</span> technique</li>
                                <li>Etude fonctionnel(optionnel)</li>
                                <li>validation du projet + devis</li>
                            </ol>
                    </div>
                    <div className='divoList'>
                           <h3 className='h3List'><strong>2.</strong> Maquettage ou Maquettage Prototypal</h3>
                            <ol>
                                <li><span className={state.helper ? 'aide' : '' } title={state.helper ? lexique.graphisme.charteGraphique.moodboard : ''}>Moodboard</span> (optionnel)</li>
                                <li><span className={state.helper ? 'aide' : '' } title={state.helper ? lexique.graphisme.charteGraphique.charte : ''}>Charte graphique</span> <span className='spanValidation'>{'=>'} validation</span></li>
                                <li><span className={state.helper ? 'aide' : '' } title={state.helper ? lexique.graphisme.charteGraphique.styleTils : ''}>Styl tils</span> <span className='spanValidation'>{'=>'} validation</span></li>
                                <li>Modèle de page d'accueil <span className='spanValidation'>{'=>'} validation</span></li>
                            </ol>
                    </div>
                  
                    <div className='divoList'>
                            <h3 className='h3List'><strong>3.</strong> Intégration:</h3>
                            <ol>
                                <li>Analyse de la charte éditorial</li>
                                <li>Règle <span className={state.helper ? 'aide' : '' } title={state.helper ? lexique.siteWeb.SEO : ''}>SEO</span></li>
                                <li>Intégration  <span className={state.helper ? 'aide' : '' }title={state.helper ? lexique.siteWeb.html : ''}>HTML</span></li>
                                <li>Intégration  <span className={state.helper ? 'aide' : '' }title={state.helper ? lexique.siteWeb.css : ''}>CSS</span></li>
                                <li>Intégration  <span className={state.helper ? 'aide' : '' }title={state.helper ? lexique.siteWeb.js : ''}>JS</span></li>
                                <li>Règle <span className={state.helper ? 'aide' : '' } title={state.helper ? lexique.siteWeb.responsive : ''}>responsive</span></li>
                                <li>Finition qualité</li>
                            </ol>
                    </div>
                    <div className='divoList'>
                            <h3 className={`h3List ${state.helper ? 'aide' : '' }`} title={state.helper ? lexique.siteWeb.hebergement : ''}><strong>4.</strong> Hébergement:</h3>
                        <ol> 
                            <li>Mise en ligne</li>
                            <li>Choix du nom de <span className={state.helper ? 'aide' : '' } title={state.helper ? lexique.siteWeb.domaine : ''}>domaine</span></li>
                            <li><span className={state.helper ? 'aide' : '' } title={state.helper ? lexique.siteWeb.maintenance : ''}>Maintenance</span></li>
                        </ol>
                    </div>
                    <div className='divoList'>       
                      <h3 className={`h3List ${state.helper ? 'aide' : '' }`} title={state.helper ? lexique.siteWeb.referencement : ''}><strong>5.</strong> Référencement</h3>
                        <ol>
                            <li><span className={state.helper ? 'aide' : '' } title={state.helper ? lexique.siteWeb.SEO : ''}>SEO</span> ou <span className={state.helper ? 'aide' : '' } title={state.helper ? lexique.siteWeb.SEA : ''}>SEA</span></li>
                        </ol>
                    </div>
            </aside>
            <aside>
                <h2>Graphisme</h2>
                        <div className='divoList'>
                            <h3 className='h3List'><strong>1.</strong> Analyse du besoin</h3>
                            <ul>
                                <li>Connaitre l'objectif du client</li>
                            </ul> 
                        </div>
                        <div className='divoList'> 
                            <h3 className='h3List'><strong>2.</strong> Création graphique</h3>
                            <ul>
                                <strong>Choix de création</strong>
                                <li>Logo</li>
                                <li>Carte de visite</li>
                                <li className={state.helper ? 'aide' : '' } title={state.helper ? lexique.graphisme.supportComVisuel.flyerBrochure : ''}>Brochure ou flyer</li>
                                <li>Affiche publicitaire</li>
                                <li className={state.helper ? 'aide' : '' } title={state.helper ? lexique.graphisme.supportComVisuel.packaging : ''}>Packaging</li>
                                <li>Charte graphique</li>
                                <li>Palette de couleur</li>
                                <li className={state.helper ? 'aide' : '' } title={state.helper ? lexique.graphisme.charteGraphique.moodboard : ''}>Moodboard</li>
                                <li>Création image</li>
                                <li>Illustration</li>
                            </ul>    
                        </div>
                        <div className='divoList'>
                            <h3 className='h3List'><strong>3.</strong> Contrat</h3>
                            <ol>
                                <li>Accompte de 30% TTC de la facture</li>
                                <li>Démarrage des travaux</li>
                                <li>Retouche et finition du service</li>
                                <li>Livraison du contenu numérique par mail ou usb</li>
                                <li>Paiement en virement bancaire ou paypal</li>
                            </ol>
                        </div>
            </aside>
        </section>
    </section>
  )
}

export default Comment