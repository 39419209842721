// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/analytics';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_G_API_KEY,
  authDomain: process.env.REACT_APP_G_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_G_PROJECT_ID,
  storageBucket: process.env.REACT_APP_G_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_G_MESSAGING_ID,
  appId: process.env.REACT_APP_G_APP_ID,
  measurementId: process.env.REACT_APP_G_MEASUREMENT_ID
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth();
const provider = new firebase.auth.GoogleAuthProvider();
const db = firebase.firestore();
const storage = firebase.storage();
const analytics = firebase.analytics();

export { auth, provider, db, analytics, storage };
