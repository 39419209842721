import React from 'react';
import linkedin from '../media/icons/linkedin.png';
import github from '../media/icons/github.jpg';
import gitlab from '../media/icons/gitlab.jpg';

function Contact() {
  return (
    <section className='contentBlock' id="idSecContact">
        <div className='pContact'>
            <p><strong className='infos'>Date de naissance :</strong> 26/05/1991</p>
            <p><strong className='infos'>Téléphone :</strong> 0621590561</p>
            <p><strong className='infos'>Adresse :</strong> 85 avenue du pic du ger 31600 Muret</p>
            <p><strong className='infos'>Email :</strong> creyoweb@gmail.com</p>
            <p><strong className='infos'>Portfolio : </strong> https://rosellini-yoan-portfolio.netlify.app</p>
        </div>
        <div className='divIcon'>
            <a href='https://www.linkedin.com/in/yoan-rosellini'><img src={linkedin} alt="linkedin" className='icon'></img></a>
            <a href='https://www.github.com/yoandevpro'><img src={github} alt="github" className='icon'></img></a>
            <a href='https://www.gitlab.com/yoandevpro'><img src={gitlab} alt="gitlab" className='icon'></img></a>
            <a href='https://www.graphiste.com/creyoweb'><img src='https://graphiste.com/assets/logo-5a998391e28e261a2664fafcc40878735f06df57563664a5167b4051395caf54.svg' alt="graphiste" className='icon'></img></a>              
        </div>
    </section>
  )
}

export default Contact