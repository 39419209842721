import { Checkbox, FormControl, FormLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { useState } from 'react';
import emailjs from "emailjs-com";
import './DevisMail.css';
import { useStateValue } from '../reducers/devisContext';
import { actionTypes } from '../reducers/devisReducer';

function DevisMail() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [message, setMessage] = useState("");
    const [date, setDate] = useState("");
    const [creneau, setCreneau] = useState("");
    const [RGPD, setRGPD] = useState(false);
    const { state, dispatch } = useStateValue();
    
    
    const handleSubmit = (e) => {
        e.preventDefault();
        let templateParams = {
          from_name: `${name}`,
          from_mail: email, 
          from_phone: number,
          enteteMessage: message && 'Avec le message :',
          message: `"${message}"`,
          consommeWeb: state.consommeWeb,
          consommeGraphique: state.consommeGraphique,
          date: date
            ? `qui souhaite être rappelé(e) le ${date} et est disponible ${creneau}`
            : null,
          selection: `<div><h4>Voici le détail</h4>${state.strings.web && state.consommeWeb !==0 ? `<div><h3>Prestations web:</h3><ul>${state.strings.web}</ul></div>.` : ''} ${state.strings.graphique && state.consommeGraphique !==0 ?`<div><h3>Prestations graphique:</h3><ul>${state.strings.graphique}</ul></div>.` : ''}`,
        };
        emailjs
          .send(
            "gmail",
            "template_nf8dvtm",
            templateParams,
            "I5lkA_FaqcKl6_p39"
          )
          .then(
            function (response) {
              alert("Votre message a bien été envoyé");
            },
            function (error) {
              alert("quelque chose s'est mal passé!");
            }
          );
        resetForm();
      };

      const resetForm = () => {
        setName("");
        setEmail("");
        setNumber("");
        setMessage("");
        setDate(undefined);
        setCreneau("");
        setRGPD(false);
        dispatch({type: actionTypes.RESET_ALL});
        // window.location.reload(false);
      };
  return (
    <form margin='normal' onSubmit={handleSubmit} id="contact">
          <p className="lead">
            En envoyant ce formulaire, vous envoyez également les prestations choisis
          </p>
          <FormControl required>
            <FormLabel htmlFor="exampleName">Nom / Prénom </FormLabel>
            <TextField
              type="text"
              name="text"
              id="exampleName"
              value={name}
              onChange={(e) => setName(e.target.value)}
              color="hightOrange"
            />
          </FormControl >
          <FormControl required>
            <FormLabel  htmlFor="exampleEmail">Email </FormLabel>
            <TextField
              type="email"
              name="email"
              id="exampleEmail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              color="hightOrange"
            />
          </FormControl>
          <FormControl >
            <FormLabel htmlFor="exampleNumber">Téléphone (facultatif)</FormLabel>
            <TextField
              type="phone"
              name="phone"
              id="exampleNumber"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              color="hightOrange"
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="exampleMessage">Message</FormLabel>
            <TextField
              type="textarea"
              name="textarea"
              id="exampleText"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              color="hightOrange"
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="date">Souhaitez vous être rappelé(e) ?</FormLabel>
            <TextField
              type="date"
              name="date"
              id="date"
              value={date ? date : ""}
              onChange={(e) => setDate(e.target.value)}
              color="hightOrange"
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="creneau">À quel moment êtes vous disponible ?</FormLabel>
            <Select
              disabled={!date}
              name="creneau"
              id="creneau"
              value={creneau}
              onChange={(e) => setCreneau(e.target.value)}
              color="hightOrange"
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value="Toute la journée">Toute la journée</MenuItem>
              <MenuItem value="De 9h00 à 12h00">De 9h00 à 12h00</MenuItem>
              <MenuItem value="Entre 12h00 et 14h00">Entre 12h00 et 14h00</MenuItem>
              <MenuItem value="De 14h00 à 18h00">De 14h00 à 18h00</MenuItem>
            </Select>
          </FormControl>
          <FormControl  margin='none'  className="RGPDGroup" required>
          <Checkbox  sx={{ '& .MuiSvgIcon-root': { fontSize: 50 },width: '5rem' }} name='RGPD' color="hightOrange" checked={RGPD} onChange={(e) => setRGPD(e.target.checked)}></Checkbox>
            <FormLabel  htmlFor="RGPD" id='rgpdText' >
              En soumettant ce formulaire j’accepte que les informations saisies
              soient exploitées dans le cadre de la relation commerciale qui
              peut en découler conformément à la politique de confidentialité. 
            </FormLabel>
          </FormControl>
          <FormControl className="buttonGroup">
            <div id="Popover1" title={"Assurez vous que les champs 'Nom', 'E-mail', 'n° de téléphone' et 'Message' sont bien remplis, puis cochez la case avant d'envoyer."} className="evenCarousel divSpansBtn">
              <button id="send"
                disabled={
                  name === "" ||
                  email === "" ||
                  RGPD === false
                }
                className="contactButton"
              >
                Envoyer
              </button>
            </div>
          </FormControl>
        </form>
  )
}

export default DevisMail