import { Alert, Checkbox, FormControl, Input, InputLabel, ListItemText, Select } from '@mui/material';
import React, { useState } from 'react';
import { CustMenuItem } from './Select';
import {colors } from '../../media';
import { analytics, db, storage } from '../../firebase/firebase';
import Snackbar from '@mui/material/Snackbar';
import { realisations } from '../Portfolio'

function ImageUpload() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedRealisation, setselectedRealisation] = useState(realisations[0]);
  const [category, setcategory] = useState("");
  const [message, setmessage] = useState("");
  const [open, setopen] = useState(false);
  const [severity, setseverity] = useState("success");
  const [colorLine, setcolorLine] = useState("");

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  function handleCategorieChange (e) {
    const real = realisations.find(element => element.id === e.target.value)
    setselectedRealisation(real);
    setcategory(real?.value);
  }

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!selectedFile || !category) {
      setmessage("merci de remplir le formulaire");
      setseverity("warning");
      setcolorLine(colors.darkBrown);
      setopen(true);
    } else {
      try {
        const storageRef = storage.ref();
        const imageRef = storageRef.child(selectedFile.name);
        await imageRef.put(selectedFile);
  
        const imageUrl = await imageRef.getDownloadURL();
  
        await db.collection('realisation').add({
          image: imageUrl,
          category: category,
          name: selectedFile.name,
          date: new Date(),
        })
        setmessage("l'enregistrement s'est bien passé");
        setseverity("success")
        setcolorLine(colors.hightOrange);
      } catch(error) {
        analytics.logEvent(error);
        setmessage("Quelque chose s'est mal passé");
        setseverity("error");
        setcolorLine("red");
      }
      setopen(true);
      setSelectedFile(null);
      setselectedRealisation(realisations[0]);
      setcategory("");
      var fileInput = document.getElementById('fileInput');
      fileInput.value = '';
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setopen(false);
  };

  return (
    <form onSubmit={handleUpload}>
      <FormControl sx={{ m: 1, width: 'fit-content', minWidth: 300}}>
        <InputLabel id='listPortfolio' sx={{color: colors.hightOrange, backgroundColor: colors.yellow, paddingRight: '5px'}}>Mes réalisations</InputLabel>
        <Select
        sx={{ border: `1px solid ${colors.lowBrown}`, '&:hover': {border: `2px solid ${colors.hightOrange}`, ".css-hfutr2-MuiSvgIcon-root-MuiSelect-icon": {color: colors.hightOrange}}, '&.Mui-focused': { border: `2px solid ${colors.hightOrange}`, ".MuiSelect-iconOpen": {color: colors.hightOrange}}, '.MuiOutlinedInput-notchedOutline': {border: 'none !important'}, '.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon': {color: colors.lowBrown}}}
        labelId="listPortfolio"
        id="demo-multiple-checkbox"
        value={selectedRealisation.id}
        onChange={handleCategorieChange}
        >
            {realisations.map((type) => (
                <CustMenuItem key={type.libelle} id={type.id} value={type.id}>
                <Checkbox sx={{display: 'none'}} checked={category === type.value} />
                <ListItemText sx={{ color: colors.hightOrange }} primary={type.libelle} />
                </CustMenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, width: 'fit-content', minWidth: 300}}>
        <Input type="file" accept="image/*" id="fileInput" onChange={handleFileChange} />
      </FormControl>
      <button type="submit">Envoyer</button>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}>
          <Alert
          onClose={handleClose}
          severity={severity}
          variant="outlined"
          sx={{ width: '100%', borderColor: colorLine, color: colorLine }}
        >
          {message}
        </Alert>
      </Snackbar>
    </form>
  );
}

export default ImageUpload;