import React from 'react'

function Lexique() {
  return (
    <section id="idLexique" className="contentBlock">
    <h1>MOTS TECHNIQUES</h1>
    <ul>
        <ul> <h2>site web</h2>
            <li><strong>arborescence du site</strong><p>Schéma illustration l'architecture et la profondeur des pages du site web.</p></li>
            <li><strong>Base de données</strong><p>Espace de stockage dans un serveur permettant de stocker des données et les renvoyer au client (naviguateur internaute).</p></li>
            <li><strong>CMS</strong><p>Content Management System: un logitiel permettant de creer des site web de manière éfficace et rapide sans coder.</p></li>
            <li><strong>css</strong><p>Le CSS est un fichier correspondant au styles du site web</p></li>
            <li><strong>Framework</strong><p>Contrairement au CMS, c'est un outils permettant de développer des projets sur mesure à partir d'une base de code existante.</p></li>
            <li><strong>Hébergement</strong><p>Il faut un espace d'hebergement web pour que le site soient mise en ligne, les services d'hebergement sont fournis par des hébergeurs.</p></li>
            <li><strong>Hébergeur</strong><p>Préstataire de services équipé de disques durs et de serveurs, qui propose aux internautes le stockage de leurs contenus et leurs diffusion sur le web.</p></li>
            <li><strong>html</strong><p>Le html est un fichier qui correspond à la structure des éléments d'une page</p></li>
            <li><strong>js</strong><p>Le javascript est un fichier qui correspond à toute les fonctionnalité dynamique d'un site (bouton survole, slider, animation etc...)</p></li>
            <li><strong>Maintenance</strong><p>La maintenance du site est un service qui permet de garder en état fonctionnel sur la durée, les bugs et disfonctionnement font partie de service de maintenance.</p></li>
            <li><strong>Référencement</strong><p>Il s'agit de l'indexation du site dans les moteurs de recherche.</p></li>
            <li><strong>Responsive</strong><p>Un site web responsive s'adapte à tout les formats d'écran.</p></li>
            <li><strong>SEO</strong><p>Référencement naturelle des pages destinés au moteur de recherche</p></li>
            <li><strong>SEA</strong><p>Référencement payant des pages destinés au moteur de recherche</p></li>
            <li><strong>Site e-commerce</strong><p>Site commercial correspondant à des produits qui sont suseptible à l'achat en ligne.</p></li>
            <li><strong>Site vitrine</strong><p>Site qui ne contient pas de fonctionnalités particulière ou d'interaction avec l'internaute, sans base de données ou de création de compte utilisateur, ou d'effet spéciaux, il ne s'agit que d'affichage d'informations.</p></li>
            <li><strong>Stack technique</strong><p>Tout les outils necessaire à la réalisation du site ou application web.</p></li>
            <li><strong>Wordpress</strong><p>c'est un CMS le plus connu et le plus utilisé au monde, il existe une grande communauté.</p></li>

        </ul><br />
        <ul> <h2>Graphisme</h2>
            <ul>
                <li><strong>Logo abstrait</strong><p>Qui n'a pas de sens propre visuellement indicatif.</p></li>
                <li><strong>Logo combiné</strong><p>Logo visuel Combiné avec du texte, généralement le nom de la marque.</p></li>
                <li><strong>Logo emblematique</strong><p>Destiné pour des Avatar ou personnage encadrant celui-ci.</p></li>
                <li><strong>Logo mascotte</strong><p>Destiné à un personnage animé.</p></li>
                <li><strong>Logo moderne</strong><p>Professionnel et en pleine tendance du moment, coût généralement plus cher que les autres.</p></li>
                <li><strong>Logo monogramme</strong><p>Utilise uniquement les initiale des première lettre de la marque.</p></li>
                <li><strong>Logo monosymbole</strong><p>Un seul symbole.</p></li>
                <li><strong>Logo pictogramme</strong><p>Une icône ou un dessin schématique représentant le plus souvent un véritable objet.</p></li>
                <li><strong>Logo typographique</strong><p>logo textuel accompagné généralement d'effet spéciaux, ombre et lumière.</p></li>
            </ul><br />
            <ul><h3>Charte graphique</h3>
                <li><strong>Déclinaison du logo</strong><p>La déclinaison du logo est un ensemble de choix possible par rapport au positionnement des éléments en fonction du format de l'écran</p></li>
                <li><strong>Iconographie</strong><p>Représente un ensemble d'icone illustrant de manière explicitement visuel comme repère</p></li>
                <li><strong>Logo</strong><p>Identité visuel représentant une marque.</p></li>
                <li><strong>Maquettes</strong><p>Fausse page web non intégré en code pour permettrant une approche sur le résultat final des pages web</p></li>
                <li><strong>Mockup</strong><p>Image représentant le produit finit dans son environnement</p></li>
                <li><strong>Moodboard</strong><p>Une planche d'inspiration ou une planche d'ambiance, permettant de donner des idée de couleurs, de contraste et de luminosité sur les thème couleur du site</p></li>
                <li><strong>Palette de couleur</strong><p>Une planche de couleur dans laquelle les couleurs principales du site sont exposées</p></li>
                <li><strong>Style tils</strong><p>Planche représentant tout les élément graphique et visuel destiné au projet web, titre, couleur de texte, choix typographique, bouton au survol etc...</p></li>
                <li><strong>Texture</strong><p>Ajouter de la texture dans les couleurs dans certains cas peut donner du relief</p></li>
                <li><strong>Typographie</strong><p>Il s'agit de toute les propriétés concernant le texte</p></li>
            </ul><br />
            <ul> <h3>Support de communication visuel</h3>
                <li><strong>Flyer et brochure</strong><p>Carte format A4 ou A5 permettant de communiquer, l'impression est fait par un imprimeur pas par le graphiste.</p></li>
                <li><strong>Packaging</strong><p>Impréssion destiné à des object physiques exemple: sac à emporter, mug, tee-shirt, casquette etc...</p></li>
                <li><strong>Stickers</strong><p>Autocolant</p></li>
                <li><strong>RGB</strong><p>Ce sont les couleurs destinées à l'écran (RED GREEN BLUE), attention le rendu graphique sur l'écran est différent que celui du CMYK.</p></li>
                <li><strong>CMYK</strong><p>Ce sont les couleurs destinées à l'impression, attention le rendu graphique sur l'impression est différent que celui du RGB.</p></li>
            </ul>
        </ul>
    </ul>
</section>
  )
}

export default Lexique