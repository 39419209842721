import React, { createContext, useContext, useReducer } from "react";
import reducer from "./devisReducer";
import { mediaBank } from "../media";

export const serviceGraphique = () => {
  const newServ = mediaBank.prestations.graphisme.prestagrapiques.map(
    (prest) => {
      const selection = prest.types.filter((type) => type.default === true);
      let consomme = 0;
      if (selection?.length > 0) {
        selection.forEach((element) => {
          consomme += element.price;
        });
      }
      return {
        name: prest.name,
        consomme: consomme,
        selection: selection || [],
      };
    }
  );
  return newServ;
};
export const serviceWeb = () => {
  const newServ = mediaBank.prestations.web.prestaweb.map((prest) => {
    const selection = prest.types.filter((type) => type.default === true);
    let consomme = 0;
    if (selection?.length > 0) {
      selection.forEach((element) => {
        consomme += element.price;
      });
    }
    return {
      name: prest.name,
      consomme: consomme,
      selection: selection || [],
    };
  });
  return newServ;
};
export const devis = () => {
  const newServ = mediaBank.prestations.web.site.tarif.options.devis.map(
    (prest) => {
      const selection = prest.types.filter((type) => type.default === true);
      let consomme = 0;
      if (selection?.length > 0) {
        selection.forEach((element) => {
          consomme += element.price;
        });
      }
      return {
        name: prest.name,
        consomme: consomme,
        selection: selection || [],
      };
    }
  );
  return newServ;
};

const strings = () => {
  const graphique = [];
  // mediaBank.prestations.graphisme.prestagrapiques.forEach((element) => {
  //   const selection = element.types.filter((type) => type.default === true);
  //   selection?.forEach((sel) => {
  //     graphique.push(`<li>${sel.name} : ${sel.price}€</li>`);
  //   });
  // });

  const web = [];
  // [
  //   ...mediaBank.prestations.web.prestaweb,
  //   ...mediaBank.prestations.web.site.tarif.options.devis,
  // ].forEach((element) => {
  //   const selection = element.types.filter((type) => type.default === true);
  //   selection?.forEach((sel) => {
  //     web.push(`<li>${sel.name} : ${sel.price}€</li>`);
  //   });
  // });

  return { graphique: graphique.join(""), web: web.join("") };
};

export const initialState = {
  helper: true,
  budgetGlobal: 0,
  consommeGraphique: 0,
  consommeWeb: 0,
  consommeDevis: 0,
  serviceGraphique: serviceGraphique(),
  serviceWeb: serviceWeb(),
  devis: devis(),
  strings: strings(),
  isServiceGraphique: false,
  isServiceWeb: false,
};
export const StateContext = createContext(initialState);

export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateValue = () => useContext(StateContext);
