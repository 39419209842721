import React, { useState } from 'react';
import {colors } from '../media';
import opquast from '../media/jpg/opquast.jpg'
import { Checkbox, FormControl, InputLabel, ListItemText, Select } from '@mui/material';
import { CustMenuItem } from './sharedComponents/Select';
import { db } from "../firebase/firebase";

export const realisations = [
    {id: '1', libelle: "", value: ""},
    {id: '2', libelle: "Logos", value: "logo" },
    {id: '3', libelle: "Illustration", value: "illustration" },
    {id: '4', libelle: "Carte de visite", value: "carteVisite" },
    {id: '5', libelle: "Flyers", value: "flyer" },
    {id: '6',libelle: "Affiches publicitaires ou évènementielles", value: "affiche" },
    {id: '7', libelle: "Charte graphique", value: "charteGraphique" },
    {id: '8', libelle: "Moodboard", value: "moodboard" },
    {id: '9', libelle: "Modele de page", value: "modelePage" },
    {id: '10', libelle: "Maquettage", value: "maquettage" },
    {id: '11', libelle: "Packaging", value: "packaging" },
    {id: '12', libelle: "Retouche / création d'image créatives", value: "creative" },
]

function Portfolio() {
    const [visible, setVisible] = useState("");
    const [images, setImages] = useState([]);
    const [imgTitle, setImgTitle] = useState("");
    const [selectedRealisation, setselectedRealisation] = useState(realisations[0])
    

    function handleCategorieChange (e) {
        const real = realisations.find(element => element.id === e.target.value)
        setselectedRealisation(real);
        setImgTitle(real?.libelle)
        getCollection(real?.value);
    }

    async function getCollection(category) {
        const images = [];
        try {
          const realisations = await db.collection("realisation").where('category', '==', category).orderBy('date', 'desc').get();
          realisations.forEach((doc) => {
            const data = doc.data();
            images.push(data.image);
          });
          setImages(images);
        } catch (error) {
          console.error("Erreur lors de la récupération des collections :", error);
        }
      }
    

  return (
    <section className="portfolio contentBlock" id="idSecPortfolio">
    <nav id='porfolio'>
          <div  className="divSpansBtn">
            <button onClick={() => setVisible('idFormations')}  id="formations" href="#formations">Mon Parcours</button>
          </div>
          <div  className="divSpansBtn">
            <button onClick={() => setVisible('idCompetences')}  id="compétences" href="#compétences">Mes Compétences</button>
          </div>
          <div  className="divSpansBtn">
            <button onClick={() => setVisible('idServices')}  id="compétences" href="#services">Mes réalisations</button>
          </div>
    </nav>    
    {visible === 'idFormations' && 
    <section className='formations' id="idFormations">
        <div>
        <div>
                <h3><button id='freelance'></button>WEBDESIGNER GRAPHIQUE FREELANCE (auto-entrepreneur)</h3>
                <strong className='infos'>depuis février 2022</strong>
            </div>          
            <div>
                <h3><button id='inachevé'></button>CONCEPTEUR DEVELOPPEUR D'APPLICATION</h3>
                <span>recherche d'un contrat d'apprentissage en alternance</span>
                <h4>AP FORMATION niveau bac  3 terminé </h4>
                <strong className='infos'>septembre 2020 à mars 2021 (6 mois)</strong>
            </div>  
        </div>
        <div>
            <div>
                <h3><button  id='simplon' ></button>DEVELOPPEUR WEB / WEB MOBILE</h3>
                <h4>SIMPLON niveau bac  2 obtenue (niveau 5)</h4>
                <strong className='infos'>juillet 2019 à mai 2020 (11 mois)</strong>
            </div>  
        </div>
        <div>
            <div className='divOpquast'>
                <h3><button  id='opquast' ></button>CERTIFICATION BONNES PRATIQUES QUALITE WEB</h3>
                <h4>OPQUAST obtenue</h4>
                <strong className='infos'>16 décembre 2019</strong>
                <img id='imgOpquast' src={opquast} alt=''/>
            </div>  
        </div>
            <div>
                <div>
                    <h3><button  id='bge' ></button>CREATION DE SITE INTERNET HTML/CSS</h3>
                    <h4>BGE TOULOUSE certification obtenue</h4>
                    <strong className='infos'>février 2017 à juillet 2017 (6mois)</strong>
                </div>  
            </div>
    </section>}

    {visible === 'idCompetences' && 
        <div className='divCompetence' id='idCompetences'>
            <h2>Compétences</h2>
        <label  className='labelCompetence'>HTML / CSS / SASS : 
                <progress className='progresser'value='90' max='100' >90</progress>
        </label>
            <label className='labelCompetence'>Adobe Photoshop : 
                <progress className='progresser' value='85' max='100' ></progress>
        </label>
            <label className='labelCompetence'>Adobe Illustrator : 
                <progress className='progresser' value='70' max='100' ></progress>
        </label>
            <label className='labelCompetence'>Adobe Indesign : 
                <progress className='progresser' value='60' max='100' ></progress>
        </label>
            <label className='labelCompetence'>Javascript : 
                <progress className='progresser' value='60' max='100' ></progress>
        </label>
            <label className='labelCompetence'>ReactJs : 
                <progress className='progresser' value='50' max='100' ></progress>
        </label>
        </div>
    }
    

    {visible === 'idServices' &&
        <section className='services contentBlock' id='idServices'>
            <h2>Réalisations</h2>
            <FormControl sx={{ m: 1, width: 'fit-content', minWidth: 300}}>
                <InputLabel id='listPortfolio' sx={{color: colors.hightOrange, backgroundColor: colors.yellow, paddingRight: '5px'}}>Mes réalisations</InputLabel>
                <Select
                sx={{ border: `3px solid ${colors.lowBrown}`, '&:hover': {border: `3px solid ${colors.hightOrange}`, ".css-hfutr2-MuiSvgIcon-root-MuiSelect-icon": {color: colors.hightOrange}}, '&.Mui-focused': { border: `3px solid ${colors.hightOrange}`, ".MuiSelect-iconOpen": {color: colors.hightOrange}}, '.MuiOutlinedInput-notchedOutline': {border: 'none !important'}, '.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon': {color: colors.lowBrown}}}
                labelId="listPortfolio"
                id="demo-multiple-checkbox"
                value={selectedRealisation.id}
                onChange={handleCategorieChange}
                >
                    {realisations.map((type) => (
                        <CustMenuItem key={type.libelle} id={type.id} value={type.id}>
                        <Checkbox sx={{display: 'none'}} checked={imgTitle === type.value} />
                        <ListItemText sx={{ color: colors.hightOrange }} primary={type.libelle} />
                        </CustMenuItem>
                    ))}
                </Select>
            </FormControl>
            <h3>{selectedRealisation.libelle}</h3>
            <div className='images' id="idImages">        
                <div className='divimg'>
                    {images?.length === 0 && imgTitle !== "" ? 
                    <p>Pas de contenu à afficher pour cette catégorie</p>
                    : 
                    images?.map((img, index) => {
                        return <div className='contimg' key = {`${imgTitle}-${index}`}><img src={img} alt={`imgEx-${index}`}/></div>
                    }) 
                    }
                </div>
            </div>
    </section>
    }
</section>
  )
}

export default Portfolio