import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import logo from "../media/png/logofinal.png";
import {Checkbox} from "@mui/material"
import { ThemeProvider } from '@mui/material/styles';
import theme  from '../components/sharedComponents/Theme.js';
import { useStateValue } from '../reducers/devisContext';
import { actionTypes } from '../reducers/devisReducer';
import "../styles/styles.css";

function Navbar(props) {
    const [touch, setTouch] = useState('off');
    const { state, dispatch } = useStateValue();
    const [helper, setHelper] = useState(state.helper);

    function toggleHelper(e) {
        setHelper(e.target.checked);
        dispatch({type: actionTypes.TOGGLE_HELP});
    }

    function changeDisplay(touch) {
        return touch === 'on' ? 
        {
            display: "block",
            position: "absolute",
            top: '-30px',
            left: '-30px',
            width: '150%',
            backgroundColor: '#a73537',
            borderRadius: '50%',
            paddingInlineStart: "0px",
            zIndex: 3000
        } : {
            display: "none"
        };

    }

    return (
        <ThemeProvider theme={theme}>
        <nav id='navBarG'>
            <NavLink to="/" ><img id="logo-creyoweb-nav" src={logo} alt="logo-creyoweb" /></NavLink>
            <div className="divSpansBtn">
                <button id ="devisBtn"><NavLink  to="/devis">Devis personnalisé</NavLink></button>
            </div>
            <div className="divSpansBtn">
                <button className="divSpansBtn" id="idRenseigne" href="#renseigne" onMouseEnter={() => setTouch('on')} onMouseLeave={() => setTouch('off')}>Me renseigner
                    <ul id="idNavPortfolio" className ="divSpansBtn" style={changeDisplay(touch)} onMouseEnter={() => setTouch('on')} onMouseLeave={() => setTouch('off')} >
                        <li><NavLink to ="/comment"> Comment </NavLink></li>
                        <li><NavLink to ="/pourquoi"> Pourquoi </NavLink></li>
                        <li><NavLink to ="/techniques"> Mots techniques </NavLink></li>
                        <li><NavLink to ="/portfolio"> Portfolio </NavLink></li>
                    </ul>
                </button>
            </div>

            <div className="divSpansBtn">
                <button id="idContact" href="#contact"><NavLink to ="/contact">Contact</NavLink></button>
            </div>
           <div className="divSpansBtn">
                <Checkbox  color="hightOrange" id="helper" checked={helper} onChange={toggleHelper}></Checkbox> <span><strong>? mode help </strong></span>
           </div>
           
        </nav>
        </ThemeProvider>
    )
}


export default Navbar