import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from '../../media/index.js';
import { useEffect } from 'react';
import { useState } from 'react';
import { useStateValue } from '../../reducers/devisContext.js';
import '../select.scss';

export const CustMenuItem = styled(MenuItem)`
  :hover {
    background-color: rgba(255, 89, 28,0.040);
  }
.Mui-selected {
    background-color:rgba(255, 89, 28,0.065);
  }

  .css-1ft9w2j-MuiButtonBase-root-MuiMenuItem-root.Mui-selected{
    background-color:rgba(255, 89, 28,0.075);
  } 
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


export default function MultipleSelectCheckmarks({prest, name, setServices, services}) {
  const [selectedServices, setselectedServices] = useState([]);
  const { state, dispatch } = useStateValue();
  
  const getDefaultValue = () => {
    const defaultServices = [];
    if (prest.multiple === true) {
      prest.types.forEach(element => {
        element.default && defaultServices.push(element.name);
      });
    }
    return defaultServices;
  }
  
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        cursor: state.helper ? 'help !important' : ''
      },
    },
  };

  const handleChange = (value) => {
      const serviceData = state[services];
      const defaultValue = getDefaultValue();
      let selectedValue = typeof value === 'string' ? value.split(',') : value;
      if(selectedValue.length < defaultValue.length) {
        selectedValue = defaultValue;
      } else {
        const confirmedValue = defaultValue;
        selectedValue.forEach(element => {
          if (!confirmedValue.find(val => val === element)) {
            confirmedValue.push(element)
          }
        });
        selectedValue = confirmedValue;
      }
      setselectedServices(selectedValue);
      const existService = serviceData.find(service => service.name === name);
      const selection = []
      selectedValue.forEach(txt => {
        const existType = prest.types.find(element=> element.name === txt);
        if (existType && existService){
          selection.push({...existType, name: txt})
        }
      });
      const newServices = serviceData?.map(serv => {
        return serv.name === existService.name ? {...existService, selection: selection} : serv
      });
      dispatch({
        type: setServices,
        payload: newServices
      });
  };

  useEffect(() => {
    handleChange(getDefaultValue())
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prest])
  

  return (
    <div title={(prest.lexique && state.helper) ? prest.lexique : '' }>
      {prest.title && <h4>{prest.title}</h4>}
      <FormControl sx={{ m: 1, maxWidth: '80vw', minWidth: '200px'}} className={(prest.lexique && state.helper) ? 'aide' : '' }>
        <InputLabel id={prest.idService || prest.idService} color= 'hightOrange' className={(prest.lexique && state.helper) ? 'aide' : '' } sx={{color: colors.hightOrange, backgroundColor: 'white', paddingRight: '5px', cursor: state.helper ? 'help !important' : ''}}>{name}</InputLabel>
        <Select
          sx={{ border: `1px solid ${colors.darkBrown}`, cursor: state.helper ? 'help !important' : '', '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: `2px solid ${colors.hightOrange}`, cursor: state.helper ? 'help !important' : ''}, '&.css-q9qgoj-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select': {cursor: state.helper ? 'help !important' : ''}, '&.muioutlinedinput-input': { cursor: 'help'}, '&.muioutlinedinput-root': { '&:hover fieldset': { cursor: 'help'}}}}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedServices}
          onChange={prest.multiple ? (e) =>handleChange(e.target.value) : ()=>{}}
          input={<OutlinedInput label="Tag" className={(prest.lexique && state.helper) ? 'aide' : '' } sx={{cursor: state.helper ? 'help !important' : ''}} />}
          // renderValue={(selected) => prest.multiple ? selected.join(', ') : [selected]}
          MenuProps={MenuProps}
          className={(prest.lexique && state.helper) ? 'aide' : '' }
        >
          {prest.multiple === true && prest.types.map((type) => (
            <CustMenuItem key={type.name} value={type.name} title={(type.lexique && state.helper) ? type.lexique : '' } className={(type.lexique && state.helper) ? 'aide' : '' }>
              <Checkbox color="hightOrange" sx={{display: 'none'}} checked={selectedServices.indexOf(type.name) > -1} disabled={type.default} />
              <ListItemText className={(type.lexique && state.helper) ? 'aide' : '' } disabled={type.default} color="hightOrange" primary={`${type.name} (${type.price ? `${type.price} €` : `${type.percent} %`})`} />
            </CustMenuItem>
          ))}
          {prest.multiple === false && (
            <RadioGroup
            id={prest.idService || prest.idService}
            aria-labelledby="demo-multiple-checkbox-label"
            value={selectedServices}
            onChange={(e)=> handleChange(e.target.value)}
            name="demo-multiple-checkbox"
          >
            <CustMenuItem key='000' value={''}>
              <FormControlLabel value="" selected={selectedServices.length === 0} control={<Radio checked={selectedServices.length === 0}/>} label={`Aucun (0 €)`}  />
            </CustMenuItem>
            {prest.types.map((type) => (
            <CustMenuItem key={type.name} title={(type.lexique && state.helper) ? type.lexique : '' } className={(type.lexique && state.helper) ? 'aide' : '' } value={type.name} control="radioGroup" selected={selectedServices.indexOf(type.name) > -1} >
              <FormControlLabel className={(type.lexique && state.helper) ? 'aide' : '' } value={type.name} control={<Radio checked={selectedServices.indexOf(type.name) > -1} />} label={`${type.name} (${type.price ? `${type.price} €` : `${type.percent} %`})`}  />
            </CustMenuItem>))}
          </RadioGroup>
          )}
        </Select>
      </FormControl>
    </div>
  );
}