import { initialState } from "./devisContext";

export const actionTypes = {
  SET_BUDGET_GLOBAL: "SET_BUDGET_GLOBAL",
  SET_SERVICE_GRAPHIQUE: "SET_SERVICE_GRAPHIQUE",
  SET_SERVICE_WEB: "SET_SERVICE_WEB",
  SET_DEVIS: "SET_DEVIS",
  RESET_ALL: "RESET_ALL",
  TOGGLE_HELP: "TOGGLE_HELP",
  SET_CONSOMME_GRAPHIQUE: "SET_CONSOMME_GRAPHIQUE",
  SET_CONSOMME_WEB: "SET_CONSOMME_WEB",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_BUDGET_GLOBAL:
      return {
        ...state,
        budgetGlobal: action.payload,
      };
    case actionTypes.SET_SERVICE_GRAPHIQUE:
      let sommeGraphique = 0;
      let pourcentGraphique = 0;
      const graphique = [];
      action.payload?.forEach((serv) => {
        serv.selection?.forEach((sel) => {
          sel.price
            ? (sommeGraphique += sel.price)
            : (pourcentGraphique += sel.percent);
          graphique.push(
            sel.price
              ? `<li>${sel.name} : ${
                  sel.type === "number"
                    ? `${sel.number} x ${sel.unitPrice}€ =`
                    : ""
                } ${sel.price}€</li>`
              : `<li>${sel.name} : ${sel.percent}% de la somme globale</li>`
          );
        });
      });
      sommeGraphique += sommeGraphique * (pourcentGraphique / 100);
      return {
        ...state,
        serviceGraphique: action.payload,
        consommeGraphique: sommeGraphique,
        strings: { ...state.strings, graphique: graphique.join("") },
      };
    case actionTypes.SET_SERVICE_WEB:
      let sommeWeb = 0;
      let pourcentWeb = 0;
      const web = [];
      const selectionWeb = [...action.payload, ...state.devis];
      selectionWeb?.forEach((serv) => {
        serv.selection?.forEach((sel) => {
          sel.price ? (sommeWeb += sel.price) : (pourcentWeb += sel.percent);
          web.push(
            sel.price
              ? `<li>${sel.name} : ${
                  sel.type === "number"
                    ? `${sel.number} x ${sel.unitPrice}€ =`
                    : ""
                } ${sel.price}€</li>`
              : `<li>${sel.name} : ${sel.percent}% de la somme globale</li>`
          );
        });
      });
      if (pourcentWeb > 0) {
        sommeWeb += sommeWeb * (pourcentWeb / 100);
      }
      return {
        ...state,
        serviceWeb: action.payload,
        consommeWeb: sommeWeb,
        strings: { ...state.strings, web: web.join("") },
      };
    case actionTypes.SET_DEVIS:
      let sommeDevis = 0;
      let pourcentDevis = 0;
      const devis = [];
      const selectionDevis = [...action.payload, ...state.serviceWeb];
      selectionDevis.forEach((serv) => {
        serv.selection?.forEach((sel) => {
          sel.price
            ? (sommeDevis += sel.price)
            : (pourcentDevis += sel.percent);
          devis.push(
            sel.price
              ? `<li>${sel.name} : ${
                  sel.type === "number"
                    ? `${sel.number} x ${sel.unitPrice}€ =`
                    : ""
                } ${sel.price}€</li>`
              : `<li>${sel.name} : ${sel.percent}% de la somme globale</li>`
          );
        });
      });
      sommeDevis += sommeDevis * (pourcentDevis / 100);
      return {
        ...state,
        devis: action.payload,
        consommeWeb: sommeDevis,
        strings: { ...state.strings, web: devis.join("") },
      };
    case actionTypes.RESET_ALL:
      return initialState;
    case actionTypes.TOGGLE_HELP:
      return { ...state, helper: !state.helper };
    case actionTypes.SET_CONSOMME_GRAPHIQUE:
      return { ...state, consommeGraphique: action.payload };
    case actionTypes.SET_CONSOMME_WEB:
      return { ...state, consommeWeb: action.payload };
    default:
      return state;
  }
};

export default reducer;
