import React, { useState } from 'react'
import InputLabel from '@mui/material/InputLabel';
import { FormControl, OutlinedInput } from '@mui/material';
import { colors } from '../../media/index.js';
import { useStateValue } from '../../reducers/devisContext.js';

function InputNumber({prest, name, id, display, setServices, services}) {
    const [num, setNum] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const { state, dispatch } = useStateValue();


    const handleChange = (event) => {
      const serviceData = state[services];
      const {
        target: { value },
      } = event;
      setNum(value);
      const existService = serviceData.find(service => service.name === name);
      const selection = []
      if (existService){
        const price = value > 0 ? prest.unitPrice * value : 0;
        selection.push({...prest, number: value, price: price, name: prest.name})
      }
      const newServices = serviceData?.map(serv => {
        return serv.name === existService.name ? {...existService, selection: selection} : serv
      });
      dispatch({type: setServices, payload: newServices})
  };
  
  return (
    <div style={{ display: display }} title={prest.lexique}>
      <FormControl name={name} id={id} fullWidth sx={{ m: 1, width: 'fit-content', minWidth: 300}} value={num} onChange={(e) => setNum(e.target.value)}>
        <InputLabel color='hightOrange' sx={{color: colors.hightOrange, backgroundColor: 'white', paddingRight: '5px'}} htmlFor="outlined-adornment-amount">Nombre de {prest.name}</InputLabel>
        <OutlinedInput
          onChange={handleChange}
          sx={{ border: `1px solid ${colors.darkBrown}`, '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: `2px solid ${colors.hightOrange}` }}}
          type="number"
          inputProps={{
            min: 0
          }}
          label="Amount"
        />
      </FormControl>
    </div>
  )
}

export default InputNumber