import affiche from "./media/jpg/creyowebAffiche.jpg";
import "./App.scss";
import "../src/styles/styles.css";
import "../src/styles/templates.css";
import "../src/styles/theme.scss";
import "../src/styles/theme.css";
import "../src/styles/theme.css.map";
import "../src/styles/mediaQueries.css";
import Navbar from "./components/Navbar.jsx";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contact from "./components/Contact";
import Comment from "./components/Comment";
import Portfolio from "./components/Portfolio";
import Lexique from "./components/Lexique";
import Pourquoi from "./components/Pourquoi";
import Devis from "./components/Devis";
import { ThemeProvider } from "styled-components";
import theme from "./components/sharedComponents/Theme.js";
import { StateProvider } from "./reducers/devisContext";
import ProtectedRoute from "./components/sharedComponents/ProtectedRoute.jsx";
import ImageUpload from "./components/sharedComponents/ImageUpload.jsx";
import Auth from "./firebase/Auth.js";
import { AuthProvider } from "./context/authContext/index.js";
import { auth } from "./firebase/firebase.jsx";

function App() {
  const [content, setContent] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <StateProvider>
          <BrowserRouter>
            <Navbar content={content} setContent={setContent} />
            <div id="bodySite">
              <Routes>
                <Route
                  exact
                  path="/"
                  element={
                    <div className="div-logo">
                      <div>
                        <h1>CREYOWEB</h1>
                        <h2 id="métier">Webdesigner / Graphiste freelance</h2>
                        <img
                          id="affiche-creyoweb"
                          src={affiche}
                          alt="affiche-creyoweb"
                        />
                      </div>
                    </div>
                  }
                />
                <Route path="/devis" element={<Devis />} />
                <Route path="/comment" element={<Comment />} />
                <Route path="/pourquoi" element={<Pourquoi />} />
                <Route path="/portfolio" element={<Portfolio />} />
                <Route path="/techniques" element={<Lexique />} />
                <Route path="/contact" element={<Contact />} />
                <Route
                  path={process.env.REACT_APP_LOGIN_URL}
                  element={<Auth />}
                />
                <Route
                  path={process.env.REACT_APP_ADMIN_URL}
                  element={
                    <ProtectedRoute isAuthenticated={isAuthenticated}>
                      <ImageUpload />
                    </ProtectedRoute>
                  }
                />
                <Route path="*" element={<p>Cette page n'existe pas!</p>} />
              </Routes>
            </div>
          </BrowserRouter>
        </StateProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
