import React from 'react'

function Pourquoi() {
  return (
    <div>
      <h1>Pourquoi choisir un freelance plutot qu'une agence de communication ou une agence web?</h1>
        <p>
          La création d'un site web correspond à de nombreuse compétences dans différents domaines de corp de metiers du numérique, 
          c'est pourquoi une agence Web ou une agence de communication est plus cher puisque il y'a toute 
          une équipe complète pour encadrer un projet web du début à la fin jusqu'a l'hébergement du site en prenant en considération le besoin du client.
        </p>
    </div>

  )
}

export default Pourquoi