import { colors } from "../../media/index";
import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  // typography: {
  //   fontFamily: [
  //     '-apple-system',
  //     'BlinkMacSystemFont',
  //     '"Segoe UI"',
  //     'Roboto',
  //     '"Helvetica Neue"',
  //     'Arial',
  //     'sans-serif',
  //     '"Apple Color Emoji"',
  //     '"Segoe UI Emoji"',
  //     '"Segoe UI Symbol"',
  //   ].join(','),
  // },
  palette: {
    yellow: {
      main: colors.yellow,
    },
    lowOrange: {
      main: colors.lowOrange,
    },
    hightOrange: {
      main: colors.hightOrange,
    },
    lowBrown: {
      main: colors.lowBrown,
    },
    darkBrown: {
      main: colors.darkBrown,
    },
  },
  components: {
    MuiChip: {
      root: {
        styleOverrides: {
          MuiInputLabel: {
            color: `${colors.hightOrange}`,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          margin: "5px",
          "&.Mui-selected": {
            backgroundColor: "rgba(255, 89, 28,0.04)",
            border: `3px solid ${colors.hightOrange}`,
          },
          "&.Mui-selected span": {
            fontWeight: "bold",
          },
          "&.Mui-selected:hover": {
            backgroundColor: "rgba(255, 89, 28,0.07)",
          },
          "&.Mui-select": {
            color: "rgba(255, 89, 28,0.04)",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          ".MuiInputBase-colorPrimary": {
            borderColor: colors.hightOrange,
            color: colors.hightOrange,
          },
          ".MuiInputBase-colorPrimary:hover": {
            borderColor: colors.hightOrange,
            color: colors.hightOrange,
          },
        },
      },
    },
  },
});

export default theme;
