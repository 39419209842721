
import opquast from "./jpg/opquast.jpg";
import github from "./icons/github.jpg";
import gitlab from "./icons/gitlab.jpg";
import goutte from "./icons/goutte.png";
import linkedin from "./icons/linkedin.png";
import petitPinceau from "./icons/petitpinceaugoutte.png";
import pinceau from "./icons/pinceau.png";
import pinceau2 from "./icons/pinceau2.png";
import pinceauGoutte from "./icons/pinceaugoutte.png";

export const lexique = {
  siteWeb: {
    arborescence:
      "Schéma illustration l'architecture et la profondeur des pages du site web.",
    bdd: "Espace de stockage dans un serveur permettant de stocker des données et les renvoyer au client (naviguateur internaute).",
    CMS: "Content Management System: un logitiel permettant de creer des site web de manière éfficace et rapide sans coder.",
    css: "Le CSS est un fichier correspondant au styles du site web",
    framework:
      "Contrairement au CMS, c'est un outils permettant de développer des projets sur mesure à partir d'une base de code existante.",
    hebergement:
      "Il faut un espace d'hebergement web pour que le site soient mise en ligne, les services d'hebergement sont fournis par des hébergeurs.",
    js: "Le javascript est un fichier qui correspond à toute les fonctionnalité dynamique d'un site (bouton survole, slider, animation etc...)",
    hebergeur:
      "Préstataire de services équipé de disques durs et de serveurs, qui propose aux internautes le stockage de leurs contenus et leurs diffusion sur le web.",
    html: "Le html est un fichier qui correspond à la structure des éléments d'une page.",
    domaine: "il s'agit de l'adresse reservé du site web (https:www...)",
    maintenance:
      "La maintenance du site est un service qui permet de garder en état fonctionnel sur la durée, les bugs et disfonctionnement font partie de service de maintenance.",
    referencement:
      "Il s'agit de l'indexation du site dans les moteurs de recherche",
    SEO: "Référencement naturel.",
    SEA: "Référencement payant.",
    responsive: "Un site web responsive s'adapte à tout les formats d'écran.",
    ecommerce:
      "Site commercial correspondant à des produits qui sont suseptible à l'achat en ligne.",
    vitrine:
      "Site qui ne contient pas de fonctionnalités particulière ou d'interaction avec l'internaute, sans base de données ou de création de compte utilisateur, ou d'effet spéciaux, il ne s'agit que d'affichage d'informations.",
    stack:
      "Tout les outils necessaire à la réalisation du site ou application web.",
    wordpress:
      "c'est un CMS le plus connu et le plus utilisé au monde, il existe une grande communauté.",
  },
  graphisme: {
    logo: {
      logoabstrait: "Qui n'a pas de sens propre visuellement indicatif.",
      logocombine:
        "Logo visuel Combiné avec du texte, généralement le nom de la marque.",
      logoemblematique:
        "Destiné pour des Avatar ou personnage encadrant celui-ci.",
      logomascotte: "Destiné à un personnage animé.",
      logomoderne:
        "Professionnel et en pleine tendance du moment, coût généralement plus cher que les autres.",
      logomonogramme:
        "Utilise uniquement les initiale des première lettre de la marque.",
      logomonosymbole: "Un seul symbole.",
      logopictogramme:
        "Une icône ou un dessin schématique représentant le plus souvent un véritable objet.",
      logotypographique:
        "Logo textuel accompagné généralement d'effet spéciaux, ombre et lumière.",
    },
    charteGraphique: {
      charte: "Tout les élément graphique d'un site web",
      declinaisonLogo:
        "La déclinaison du logo est un ensemble de choix possible par rapport au positionnement des éléments en fonction du format de l'écran",
      iconographie:
        "Représente un ensemble d'icone illustrant de manière explicite les repères visuel d'une application ou autre documents",
      logo: "Identité visuel représentant une marque.",
      maquettes:
        "Fausse page web non intégré en code pour permettrant une approche sur le résultat final des pages web",
      mockup: "Image représentant le produit finit dans son environnement",
      moodboard:
        "Une planche d'inspiration ou une planche d'ambiance, permettant de donner des idée de couleurs, de contraste et de luminosité sur les thème couleur du site",
      paletteCouleur:
        "Une planche de couleur dans laquelle les couleurs principales du site sont exposées",
      styleTils:
        "Planche représentant tout les élément graphique et visuel destiné au projet web, titre, couleur de texte, choix typographique, bouton au survol etc.",
      texture:
        "Ajouter de la texture dans les couleurs dans certains cas peut donner du relief",
      typographie: "Il s'agit de toute les propriétés concernant le texte",
    },
    supportComVisuel: {
      flyerBrochure: "dépliant destiné à la communication et à distribuer.",
      packaging:
        "impréssion destiné à des object physiques exemple: sac à emporter, mug, tee-shirt, casquette etc.",
      stickers: "Autocolant",
    },
  },
};
const packaging = ["tee-shirt", "bag", "mug", "autre objets"];
const prestaName = [
  "Carte de visite",
  "flyer",
  "affiche publicitaire",
  "sticker",
  "carte restaurant",
  "document administratif",
  packaging,
];
// let arrTarif = [];
// let userChoice = 1;
let min = 600;
// let packName = ["basic", "medium", "pro", "elite", "ultime", "unique"];
const graphisme = {
  prestagrapiques: [
    {
      idService: "gr1",
      type: "select",
      multiple: false,
      name: "Logo",
      types: [
        {
          idType: 1,
          name: "Typographique",
          price: 120,
          lexique: lexique.graphisme.logo.logotypographique,
        },
        {
          idType: 2,
          name: "Mascotte",
          price: 150,
          lexique: lexique.graphisme.logo.logomascotte,
        },
        {
          idType: 3,
          name: "Moderne",
          price: 200,
          lexique: lexique.graphisme.logo.logomoderne,
        },
        {
          idType: 4,
          name: "emblématique",
          price: 150,
          lexique: lexique.graphisme.logo.logoemblematique,
        },
        {
          idType: 5,
          name: "combiné",
          price: 120,
          lexique: lexique.graphisme.logo.logocombine,
        },
        {
          idType: 6,
          name: "abstrait",
          price: 100,
          lexique: lexique.graphisme.logo.logoabstrait,
        },
        {
          idType: 7,
          name: "pictogramme",
          price: 100,
          lexique: lexique.graphisme.logo.logopictogramme,
        },
        {
          idType: 8,
          name: "monosymbole",
          price: 100,
          lexique: lexique.graphisme.logo.logomonosymbole,
        },
        {
          idType: 9,
          name: "monogramme",
          price: 100,
          lexique: lexique.graphisme.logo.logomonogramme,
        },
      ],
    },
    {
      idService: "gr2",
      multiple: true,
      type: "select",
      name: "Charte graphique",
      types: [
        {
          idType: 10,
          name: "Déclinaison du logo",
          price: 20,
          lexique: lexique.graphisme.charteGraphique.declinaisonLogo,
        },
        {
          idType: 11,
          name: "Typographie",
          price: 50,
          lexique: lexique.graphisme.charteGraphique.typographie,
        },
        {
          idType: 12,
          name: "Palette de couleur",
          price: 30,
          lexique: lexique.graphisme.charteGraphique.paletteCouleur,
        },
        {
          idType: 13,
          name: "Moodboard",
          price: 30,
          lexique: lexique.graphisme.charteGraphique.moodboard,
        },
        {
          idType: 14,
          name: "Styl tils",
          price: 60,
          lexique: lexique.graphisme.charteGraphique.styleTils,
        },
        {
          idType: 15,
          name: "Iconographie",
          price: 150,
          lexique: lexique.graphisme.charteGraphique.iconographie,
        },
        {
          idType: 16,
          name: "Mockup",
          price: 30,
          lexique: lexique.graphisme.charteGraphique.mockup,
        },
      ],
    },
    {
      idService: "gr3",
      title: "Maquettage",
      name: "Maquettage",
      type: "section",
      types: [
        {
          idType: 17,
          type: "number",
          name: "modèles",
          unitPrice: 40,
        },
        {
          idType: 18,
          type: "number",
          name: "pages",
          unitPrice: 25,
          dependOf: "gr3-t1",
        },
      ],
    },
    {
      idService: "gr4",
      multiple: true,
      type: "select",
      name: "Illustration",
      types: [
        {
          idType: 19,
          name: "Déssin illustration artistique",
          price: 100,
        },
        {
          idType: 20,
          name: "Déssin illustration réaliste",
          price: 120,
        },
        {
          idType: 21,
          name: "Déssin illustration professionnel",
          price: 200,
        },
      ],
    },
  ],
  serviceName: "graphisme",
  logo: {
    prestaName: "Logo",
    tarif: {
      basic: 120,
      medium: 170,
      pro: 220,
      elite: 350,
      ultime: 500,
      unique: 2000,
    },
  },
  charteGraphique: {
    prestaName: "Charte Graphique",
    tarif: {
      basic: 70,
      medium: 90,
      pro: 130,
      elite: 150,
      ultime: 200,
      unique: 300,
    },
  },
  print: {
    prestarif: {
      carte: {
        name: prestaName[0],
        tarif: 50,
      },
      flyer: {
        name: prestaName[1],
        tarif: 80,
      },
      affiche: {
        name: prestaName[2],
        tarif: 90,
      },
      sticker: {
        name: prestaName[3],
        tarif: 50,
      },
      carteResto: {
        name: prestaName[4],
        tarif: 70,
      },
      doc: {
        name: prestaName[5],
        tarif: 70,
      },
      petitObj: {
        name: [packaging[0], packaging[1], packaging[2]],
        tarif: 80,
      },
      autreObj: {
        name: packaging[3],
        tarif: 80,
      },
    },
  },
};
const web = {
  prestaweb: [
    {
      idService: "w1",
      multiple: false,
      type: "select",
      name: "Site web",
      types: [
        {
          idType: 1,
          name: "Vitrine",
          price: 600,
          lexique: lexique.siteWeb.vitrine,
        },
        {
          idType: 2,
          name: "Dynamique",
          price: 2000,
        },
        {
          idType: 3,
          name: "Intéractif",
          price: 5000,
        },
        {
          idType: 4,
          name: "E-commerce",
          price: 3000,
          lexique: lexique.siteWeb.ecommerce,
        },
        {
          idType: 5,
          name: "Spécifique",
          price: 5000,
          lexique:
            "Le cahier des charges spécifiques sera élaboré en collaboration avec le prospect.",
        },
      ],
    },
    {
      idService: "w2",
      multiple: true,
      type: "select",
      name: "Taille d'écran",
      types: [
        {
          idType: 6,
          name: "Bureau",
          price: 100,
        },
        {
          idType: 7,
          name: "Tablette",
          price: 200,
        },
        {
          idType: 8,
          name: "Mobile",
          price: 200,
        },
      ],
    },
    {
      idService: "w3",
      multiple: false,
      type: "select",
      name: "Référencement",
      lexique: lexique.siteWeb.SEO,
      types: [
        {
          idType: 9,
          name: "Réferencement naturel basique",
          percent: 5,
        },
        {
          idType: 10,
          name: "Réferencement naturel optimisé",
          percent: 7,
        },
      ],
    },
  ],
  serviceName: "site web",
  site: {
    siteName: ["Vitrine", "Dynamique", "Bdd", "Mvp", "E-commerce"],
    tarif: {
      vitrine: min,
      frontJS: 2500,
      mvp: 6000,
      wp: min,
      eco: 5000,
      options: {
        responsive: 300,
        seo: Boolean,
        graphisme,
        devis: [
          {
            id: 1,
            name: "Analyse du besoin du site",
            required: true,
            type: "select",
            multiple: true,
            seoManagement: true, // si dans les étape d'un stepName il y'a au moins un seo=true || que chaque élément contenant un required contenant un seo=true alors devis.seoManagement= true sinon devis.seoManagement=false
            types: [
              {
                id: 1,
                name: "Cahier des charges",
                default: false,
                seo: true,
                lexique:
                  "Un cahier numérique qui pourra se transmettre entre les autres professionnels des metiers du numérique pour suivre à la trace les travaux réalisés en fonction de l'étude du besoin.",
                price: 90,
              },
              {
                id: 2,
                name: "Etude de faisabilité et périmètre du projet",
                default: true,
                seo: true,
                lexique:
                  "Avant de creer un site, il faut savoir quel est l'objectif final et pourquoi en fonction du besoin et du projet afin d'établir un périmettre d'intervention sur les prestations réalisables.",
                price: 60,
              },
              {
                id: 3,
                name: "Analyse concurentiel",
                default: false,
                seo: true,
                lexique:
                  "Avant de s'engager dans la création du site, il est important d'analyser la concurence de vos services pour ne pas s'engager dans une mauvaise stratégie marketing.",
                price: 190,
              },
              {
                id: 4,
                name: "Analyse des mots clés",
                default: false,
                seo: true,
                lexique:
                  "Une étude d'analyse de mots clé est necessaire si y'a besoin de service en référencement dans les moteurs de recherche.",
                price: 250,
              },
              {
                id: 5,
                name: "Etude du choix technique",
                default: true,
                seo: true,
                lexique:
                  "Il y'a plusieurs manière de créer un site web en fonction du besoin de la structure interne, cette étape est déterminé précédement dans le cahier des charges.",
                price: 50,
              },
              {
                id: 6,
                name: "référencement",
                default: false,
                seo: true,
                lexique:
                  "Etape indipensable et crucial pour augmenter la visibilité et notoriété du site mais pas indispensable par rapport à d'autre stratégies marketing.",
                price: 500,
              },
            ],
          },
          {
            id: 2,
            name: "Préparation et Maquettage",
            required: false,
            type: "select",
            multiple: true,
            seoManagement: true,
            types: [
              {
                id: 8,
                name: "Charte graphique, Styl tils et Moodboard",
                default: false,
                seo: true, // prise en compte de l'UX / UI
                price: 180,
                lexique: `Chartegraphique : ${lexique.graphisme.charteGraphique.charte}Style tils : ${lexique.graphisme.charteGraphique.styleTils} Moodboard: ${lexique.graphisme.charteGraphique.moodboard}`,
                custumization: {
                  charte: {
                    state: Boolean,
                    options: {
                      logo: Boolean,
                      typo: true,
                      palette: true,
                      declinaison: Boolean,
                      iconographie: Boolean,
                      texture: Boolean,
                      elements: Boolean,
                      moodboard: Boolean,
                      mockup: Boolean,
                      maquette: Boolean,
                    },
                  },
                  styltil: {
                    state: Boolean,
                    price: 60,
                  },
                  moodboard: {
                    state: Boolean,
                    price: 20,
                  },
                },
              },
              {
                id: 9,
                name: "Maquettage",
                default: false,
                type: "select",
                seo: true, // prise en compte de UX / UI
                clientCanHave: true,
                lexique:
                  "Avant la conception du site, il est obligatoire de valider des maquettes de pages pour ne pas à les remodifier ou refacturer mais si cette option n'est pas choisit, le prestataire se donne le droit d'integrer les pages avec le design qu'il souhaite et le client ne pourra pas demander au prestataire de remodifier le designe pendant l'étape d'intégration.Le tarif dépand de l'arborescence du site en fonction du nombre de page",
                price: 70,
                option: {
                  deskop: {
                    state: Boolean,
                    tarif: 50,
                    modele: {
                      state: Boolean,
                      tarif: 40,
                      full: { state: Boolean, tarif: 120 },
                    },
                  },
                  mobile: {
                    state: Boolean,
                    tarif: 150,
                    modele: {
                      state: Boolean,
                      tarif: 50,
                      full: { state: Boolean, tarif: 170 },
                    },
                  },
                },
              },
            ],
          },
          {
            id: 3,
            name: "Intégration",
            required: true,
            type: "select",
            multiple: true,
            seoManagement: true, // charte rédactionnel claire et précise
            lexique:
              "Il s'agit de l'intégration des maquettes en page web en fonction de la technique informatique utilisée.",
            types: [
              {
                id: 10,
                name: "Analyse editorial",
                default: false,
                seo: true, // semantique HTML, matedescription, backlinks, pertinence
                lexique: "Analyse sémantique du texte pour le SEO",
                price: 60,
              },
              {
                id: 11,
                name: "Regle SEO",
                default: false,
                seo: true,
                lexique: "Vérification de toute les règles essentiel au SEO",
                price: 400,
              },
              {
                id: 12,
                name: "HTML",
                default: false,
                seo: true, // metadescription
                lexique: "Intégration des données structurelle de la page",
                price: 170,
              },
              {
                id: 13,
                name: "CSS",
                default: true,
                seo: false,
                lexique: "Integration du code CSS destiné au style du site",
                price: 200,
              },
              {
                id: 14,
                name: "JS",
                default: false,
                seo: false,
                lexique:
                  "Intégration fonctionnelle d'élément dynamique du site",
                price: 350,
              },
              {
                id: 15,
                name: "Règle responsive",
                default: true,
                seo: true, // UX UI formats
                lexique: "Adaption du code CSS format bureau au format mobile",
                price: 150,
              },
              {
                id: 16,
                name: "Finition qualité",
                default: true,
                seo: true,
                lexique: "Affinage et controle qualité des bonne pratique web",
                price: 90,
              },
              {
                id: 17,
                name: "Hébergement",
                default: false,
                seo: true, // si hébergé en ligne avec nom de domaine.
                lexique:
                  "l'hebergement est à la charge du client car ce n'est pas réalisable par le prestataire",
                price: 80,
              },
              {
                id: 18,
                name: "Choix du nom de domaine",
                default: false,
                seo: false,
                lexique: "exemple: nomdusite.com",
                price: 30,
              },
              {
                id: 19,
                name: "Maintenance",
                default: false,
                seo: false, // si le serveur crash, les datas sont plus référencé? donc true
                lexique:
                  "Service de maintenance technique pour maintenir en état de fonctionnement le site web, de réactualisation et de prévention en cas d'incident technique",
                price: 60,
              },
              {
                id: 20,
                name: "Referencement",
                default: false,
                seo: true,
                lexique:
                  "Avoir un visuel statistique des données de croissance du site web",
                price: 350,
              },
              {
                id: 21,
                name: "Sécurité",
                default: false,
                seo: true, // pour une bonne maintenance = SEO (il me semble)
                lexique:
                  "Maintenir la sécurité et protection d'éventuelle attaques ou de piratage informatique",
                price: 400,
              },
            ],
          },
        ],
      },
    },
  },
};

export const mediaBank = {
  prestations: {
    graphisme,
    web,
  },
  reduction: [0.05, 0.1, 0.2, 0.3],
    icons: [
      github,
      gitlab,
      goutte,
      linkedin,
      petitPinceau,
      pinceau,
      pinceau2,
      pinceauGoutte,
      opquast,
    ],
};
export const colors = {
  darkBrown: "#a73537",
  lowBrown: "#af5121",
  hightOrange: "#ff591c",
  lowOrange: "#e45828",
  yellow: "#ffd372",
};
