import React, { useState, useEffect } from "react";
import { auth, provider } from "./firebase";

const Auth = () => {
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const signInWithGoogle = async () => {
    try {
      await auth.signInWithPopup(provider);
    } catch (error) {
      setError(error.message);
    }
  };

  const signOut = () => {
    auth.signOut();
  };

  return (
    <div>
      {user ? (
        <button onClick={signOut}>Sign Out</button>
      ) : (
        <button onClick={signInWithGoogle}>Sign In with Google</button>
      )}
      {error && <p>{error}</p>}
    </div>
  );
};

export default Auth;
